import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import CookieContext from '../context/CookieContext';
import cookieDataDE from '../data/de/cookie/index.json';
import cookieDataEN from '../data/en/cookie/index.json';
import cookieDataES from '../data/es/cookie/index.json';

const CookieBanner = ({ language }) => {
  const { saveCookie } = useContext(CookieContext);
  const [showBanner, setShowBanner] = useState(true);

  let cookieData;
  switch (language) {
    case 'es':
      cookieData = cookieDataES;
      break;
    case 'en':
      cookieData = cookieDataEN;
      break;
    default: cookieData = cookieDataDE;
  }

  return (
    (showBanner ? (
      <Container className="cookie" fluid>
        <Container>
          <Row>
            <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 9 } xl={ 9 }>
              <p>
                { cookieData.cookieInformation }
              </p>
              <p>
                { cookieData.furtherInformation} <Link to={ cookieData.privacy.route }>{ cookieData.privacy.label }</Link>.
              </p>
            </Col>
            <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 3 } xl={ 3 } className="text-center">
              <ButtonGroup vertical className="d-flex">
                <Button variant="primary btn-lg" onClick={ () => saveCookie(1) }>{ cookieData.acceptLabel }</Button> <p />
                <Button variant="secondary btn-lg" onClick={ () => saveCookie(0) }>{ cookieData.declineLabel }</Button>
              </ButtonGroup>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <p
                style={{
                  cursor: 'pointer', textDecorationLine: 'underline', fontSize: '1rem', marginTop: '15px'
                }}
                onClick={ () => setShowBanner(false) }
              >
                { cookieData.hideLabel }
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    )
      : null)
  );
};

CookieBanner.propTypes = {
  language: PropTypes.string.isRequired
};

export default CookieBanner;