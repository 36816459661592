import '../styles/custom.scss';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import navBarDataDE from '../data/de/navBar/index.json';
import navBarDataEN from '../data/en/navBar/index.json';
import navBarDataES from '../data/es/navBar/index.json';
import LanguageSwitcher from './LanguageSwitcher';

const NavBar = ({ slug, language }) => {
  let navBarData;
  let logoLink = '/';
  switch (language) {
    case 'es':
      navBarData = navBarDataES;
      logoLink = '/es/';
      break;
    case 'en':
      navBarData = navBarDataEN;
      logoLink = '/en/';
      break;
    default: navBarData = navBarDataDE;
  }

  const renderNavBarItem = (navBarItem, i) => {
    const fontWeightForSlug = (route) => (slug !== '/' && route !== '/' && slug.includes(route) ? 'font-weight-bold' : '');
    const itemClassList = (route) => `text-primary my-auto ml-3 text-decoration-none py-2 ${ fontWeightForSlug(route) }`;
    const ctaClassList = (route) => `my-auto ml-3 text-decoration-none btn btn-primary ${ fontWeightForSlug(route) }`;
    const navBarItemsLength = navBarDataDE.navBarItems.length;

    if (navBarItem.externalLink) {
      return (
        <Nav.Link href={ navBarItem.route } target="_blank" className={ navBarItemsLength - 1 !== i ? itemClassList(navBarItem.route) : ctaClassList(navBarItem.route) } key={ navBarItem.text }>
          { navBarItem.text }
        </Nav.Link>
      );
    }
    if (navBarItem.children) {
      return (
        <Dropdown key={ navBarItem.text } className="position-static">
          <Dropdown.Toggle as={ Nav.Link } className={ navBarItemsLength - 1 !== i ? itemClassList(navBarItem.route) : ctaClassList(navBarItem.route) }>
            { navBarItem.text }
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100 border-0 shadow" style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
            <Container className="justify-content-center">
              <Row className="w-100">
                {navBarItem.children.map((child) => (
                  <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 } className="my-3" key={ child.text }>
                    <Link to={ `${ navBarItem.route }${ child.route }` } className={ itemClassList(child.route) }>{ child.text }</Link>
                    <ul className="list-unstyled">
                      {child.children && child.children.map((grandChild) => (
                        <li key={ grandChild.text }>
                          <Link to={ `${ navBarItem.route }${ child.route }${ grandChild.route }` } className={ `${ itemClassList(grandChild.route) } text-dark` }>{ grandChild.text }</Link>
                        </li>
                      ))}
                    </ul>
                  </Col>
                ))}
              </Row>
            </Container>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <Link to={ navBarItem.route } className={ navBarItemsLength - 1 !== i ? itemClassList(navBarItem.route) : ctaClassList(navBarItem.route) } key={ navBarItem.text }>
        { navBarItem.text }
      </Link>
    );
  };

  return (
    <Navbar expand="lg" bg="white" fixed="top" className="shadow mb-0 py-3 py-lg-0">
      <Container>
        <Link to={ logoLink } style={{ maxWidth: '75%' }}>
          <img src={ navBarData.logo } style={{ maxWidth: '100%', maxHeight: '40px' }} alt="Logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="my-3">
          <Nav className="ml-auto">
            <LanguageSwitcher slug={ slug } language={ language } />
            {navBarData.navBarItems.map(
              (navBarItem, i) => renderNavBarItem(navBarItem, i)
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

NavBar.propTypes = {
  slug: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default NavBar;