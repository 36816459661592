import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';

const LanguageSwitcher = ({ slug, language }) => {
  let urlDE = slug;
  let urlEN = slug;
  let urlES = slug;

  switch (slug.substr(0, 4)) {
    case '/en/':
      urlDE = slug.replace('/en/', '/');
      urlES = slug.replace('/en/', '/es/');
      break;
    case '/es/':
      urlDE = slug.replace('/es/', '/');
      urlEN = slug.replace('/es/', '/en/');
      break;
    default:
      urlEN = slug.replace('/', '/en/');
      urlES = slug.replace('/', '/es/');
  }

  return (
    <Dropdown className="ml-3">
      <Dropdown.Toggle as={ Nav.Link } className="text-primary">
        { language.toUpperCase() }
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item>
          <Link to={ urlDE } className={ `${ language === 'de' ? 'font-weight-bold' : '' } text-decoration-none` }>DE</Link>
        </Dropdown.Item>
        <Dropdown.Item>
          <Link to={ urlEN } className={ `${ language === 'en' ? 'font-weight-bold' : '' } text-decoration-none` }>EN</Link>
        </Dropdown.Item>
        <Dropdown.Item>
          <Link to={ urlES } className={ `${ language === 'es' ? 'font-weight-bold' : '' } text-decoration-none` }>ES</Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

LanguageSwitcher.propTypes = {
  slug: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default LanguageSwitcher;