import '../styles/custom.scss';

import { withPrefix } from 'gatsby';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import CookieContext from '../context/CookieContext';
import CookieBanner from './CookieBanner';
import Footer from './Footer';
import NavBar from './NavBar';

const Layout = ({
  children, title, description, slug, language
}) => {
  const [consent, setConsent] = useState(undefined);
  const [gaInitialised, setGaInitialised] = useState(false);
  const saveConsent = (newValue) => {
    Cookies.set('consent', newValue, { expires: 365 });
    setConsent(newValue);
  };

  const cookieIsNotSet = () => consent === undefined;

  const trackPageView = () => {
    if (gaInitialised) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  };

  useEffect(() => {
    const consentCookie = Cookies.get('consent');
    // eslint-disable-next-line eqeqeq
    if (consentCookie == 0 || consentCookie == 1) {
      setConsent(consentCookie);
    }
  }, []);

  useEffect(() => {
    if (consent === 1) {
      ReactGA.initialize('UA-23799647-2');
      ReactGA.set({ anonymizeIp: true });
      setGaInitialised(true);
    }
  }, [consent]);

  return (
    <CookieContext.Provider value={{
      cookie: consent,
      saveCookie: saveConsent
    }}
    >
      <div>
        <Helmet>
          <html lang={ language } />
          <title>SET | {title}</title>
          <meta name="description" content={ description } />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={ `${withPrefix('/')}img/apple-touch-icon.png` }
          />
          <link
            rel="icon"
            type="image/png"
            href={ `${withPrefix('/')}img/favicon-32x32.png` }
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={ `${withPrefix('/')}img/favicon-16x16.png` }
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href={ `${withPrefix('/')}img/safari-pinned-tab.svg` }
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={ title } />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={ `${withPrefix('/')}img/og-image.jpg` }
          />
        </Helmet>
        {trackPageView()}
        <NavBar slug={ slug } language={ language } />
        <div className="d-flex flex-column min-vh-100 min-vw-100">
          {children}
          {cookieIsNotSet()
          && <CookieBanner language={ language } />}
          <Footer language={ language } />
        </div>
      </div>
    </CookieContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

Layout.defaultProps = {
  title: '',
  description: ''
};

export default Layout;
