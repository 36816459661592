import {
  mdiFacebook, mdiLinkedin, mdiTwitter, mdiXing
} from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import footerDataDE from '../data/de/footer/index.json';
import footerDataEN from '../data/en/footer/index.json';
import footerDataES from '../data/es/footer/index.json';

const Footer = ({ language }) => {
  const iconMapper = {
    facebook: mdiFacebook,
    twitter: mdiTwitter,
    xing: mdiXing,
    linkedin: mdiLinkedin
  };

  let footerData;
  switch (language) {
    case 'es':
      footerData = footerDataES;
      break;
    case 'en':
      footerData = footerDataEN;
      break;
    default: footerData = footerDataDE;
  }

  const footerItemRenderer = (footerItem) => {
    if (footerItem.externalLink) {
      return (
        <Nav.Link href={ footerItem.route } className="my-auto mx-auto ml-lg-0 mr-lg-3 text-decoration-none text-white" key={ footerItem.text }>
          {footerItem.text}
        </Nav.Link>
      );
    }

    return (
      <Link to={ footerItem.route } className="my-auto mx-auto ml-lg-0 mr-lg-3 text-decoration-none text-white" key={ footerItem.text }>
        {footerItem.text}
      </Link>
    );
  };

  return (
    <Navbar expand="lg" sticky="bottom" bg="primary">
      <Container>
        <Row noGutters className="justify-content-between w-100">
          <Col xs={ 12 } lg={ 9 }>
            <Nav>
              { footerData.footerItems.map((footerItem) => (
                footerItemRenderer(footerItem)
              ))}
            </Nav>
          </Col>
          <Col xs={ 12 } lg={ 3 }>
            <Nav>
              <div className="mx-auto ml-lg-auto mr-lg-0">
                { footerData.socialMedia.map((item) => (
                  <a href={ item.route } key={ item.network }>
                    <Icon path={ iconMapper[item.network] } size={ 1 } className="mx-auto mr-lg-0 ml-lg-3" color="white" />
                  </a>
                ))}
              </div>
            </Nav>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

Footer.propTypes = {
  language: PropTypes.string.isRequired
};

export default Footer;